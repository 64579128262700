<template>
  <CRow>
    <CCol sm="12">
      <CToaster :autohide="3000">
        <template v-for="(toast, index) in toasts">
          <CToast
            class="text-white"
            v-bind:class="'bg-' + toast.level"
            :key="'toast' + index"
            :show="true"
          >
            {{ toast.message }}
          </CToast>
        </template>
      </CToaster>
      <CCard>
        <CCardHeader> <CIcon name="cil-notes" /> 月次確定処理 </CCardHeader>
        <CForm>
          <CCardBody>
            <CAlert v-show="!closable && isCloseUser()" color="danger" closeButton>
              月中または使用クーポンがない場合、確定できません
            </CAlert>
            <CRow>
              <CCol col="12" sm="6">
                <CCallout color="danger">
                  <strong class="h3">{{ user.name }}</strong
                  ><br />
                </CCallout>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="3">
                <CSelect
                  :options="closingYearMonths"
                  :value.sync="userClosing.year_month"
                  @update:value="changeYearMonth($event)"
                  prepend="確定年月"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol v-show="isCloseUser()" col="12" sm="3" md="3" lg="2">
                <CCallout color="info" class="b-t-1 b-r-1 b-b-1">
                  <small>確定状況</small><br />
                  <strong class="h5">
                    <span v-show="!userClosing.closed" class="text-danger font-weight-bold">未確定</span>
                    <span v-show="userClosing.closed" class="text-primary font-weight-bold">確定済</span>
                  </strong>
                </CCallout>
              </CCol>
              <CCol col="12" sm="3" md="3" lg="2">
                <CCallout color="info" class="b-t-1 b-r-1 b-b-1">
                  <small>月次使用枚数</small><br />
                  <strong class="h5">{{ userClosing.purchaseQuantity }}</strong>
                  <small>&nbsp;枚</small>
                </CCallout>
              </CCol>
              <CCol col="12" sm="3" md="3" lg="2">
                <CCallout color="info" class="b-t-1 b-r-1 b-b-1">
                  <small>月次割引金額</small><br />
                  <strong class="h5">{{ formatPrice(userClosing.couponPrice) }}</strong>
                  <small>&nbsp;円</small>
                </CCallout>
              </CCol>
              <CCol v-show="isCloseUser()" col="12" sm="3" md="3" lg="2" style="margin-top: 20px">
                <CButton
                  color="primary"
                  size="lg"
                  style="width: 150px"
                  v-show="!ocClosed"
                  :disabled="!closable"
                  @click="userClosing.closed ? release() : close()"
                >
                  {{ userClosing.closed ? "確定解除" : "確定" }}
                </CButton>
              </CCol>
            </CRow>
            <CRow v-show="isCloseUser()">
              <CCol col="12" sm="10" md="9" lg="11">
                申し送り事項
                <CButton
                  color="info"
                  v-show="!userClosing.closed"
                  style="margin: 5px 5px 5px 15px"
                  @click="registerNote()"
                >
                  登録
                </CButton>
                <CTextarea :disabled="userClosing.closed" horizontal rows="4" v-model="userClosing.note" />
              </CCol>
            </CRow>
          </CCardBody>
        </CForm>
      </CCard>
      <CCard>
        <CCardHeader> <CIcon name="cil-notes" /> 絞り込み </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol md="6">
                <CInput label="期間（開始）" type="date" v-model="form.startDate" />
              </CCol>
              <CCol md="6">
                <CInput label="期間（終了）" type="date" v-model="form.endDate" />
              </CCol>
            </CRow>
            <div v-if="this.isDistributions()">
              <CRow>
                <CCol md="6">
                  <CSelect
                    label="メーカー"
                    horizontal
                    :options="makerPullDown"
                    placeholder="Please select"
                    :value.sync="form.makerId"
                    @update:value="onUpdateMaker($event)"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CInput label="JANコード" placeholder="" v-model="form.janCode" />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CSelect
                    label="店舗"
                    horizontal
                    :options="storePullDown"
                    placeholder="Please select"
                    :value.sync="form.storeId"
                    @update:value="onUpdateStore($event)"
                  />
                </CCol>
              </CRow>
            </div>
            <div v-if="this.isMakers()">
              <CRow>
                <CCol md="6">
                  <CInput label="JANコード" placeholder="" v-model="form.janCode" />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CSelect
                    label="小売"
                    horizontal
                    :options="distributionPullDown"
                    placeholder="Please select"
                    :value.sync="form.distributionId"
                    @update:value="onUpdateDistribution($event)"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CSelect
                    label="店舗"
                    horizontal
                    :options="storePullDown"
                    placeholder="Please select"
                    :value.sync="form.storeId"
                    @update:value="onUpdateStore($event)"
                  />
                </CCol>
              </CRow>
            </div>
            <div v-if="this.isWholeSales()">
              <CRow>
                <CCol md="6">
                  <CSelect
                    label="小売"
                    horizontal
                    :options="distributionPullDown"
                    placeholder="Please select"
                    :value.sync="form.distributionId"
                    @update:value="onUpdateDistribution($event)"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CSelect
                    label="メーカー"
                    horizontal
                    :options="makerPullDown"
                    placeholder="Please select"
                    :value.sync="form.makerId"
                    @update:value="onUpdateMaker($event)"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CInput label="JANコード" placeholder="" v-model="form.janCode" />
                </CCol>
              </CRow>
            </div>
            <div v-if="this.isRegisters()">
              <CRow>
                <CCol md="6">
                  <CInput label="JANコード" placeholder="" v-model="form.janCode" />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CSelect
                    label="小売"
                    horizontal
                    :options="distributionPullDown"
                    placeholder="Please select"
                    :value.sync="form.distributionId"
                    @update:value="onUpdateDistribution($event)"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CSelect
                    label="店舗"
                    horizontal
                    :options="storePullDown"
                    placeholder="Please select"
                    :value.sync="form.storeId"
                    @update:value="onUpdateStore($event)"
                  />
                </CCol>
              </CRow>
            </div>
            <CRow>
              <CCol>
                <CButton
                  color="info"
                  style="margin: 5px 5px 5px 15px"
                  @click="search()"
                >
                  絞り込み
                </CButton>
                <CButton
                  color="info"
                  style="margin: 5px 5px 5px 15px"
                  @click="download()"
                >
                  CSV出力
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>

      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid" />
          日次履歴
        </CCardHeader>
        <CCardBody>
          <CDataTable :items="dailyUsages" :fields="fields" sorter>
            <template #formattedDate="{ item }">
              <td class="text-center">
                {{ item.formattedDate }}
              </td>
            </template>

            <template #purchaseQuantity="{ item }">
              <td class="text-right">{{ item.purchaseQuantity }}枚</td>
            </template>

            <template #couponPrice="{ item }">
              <td class="text-right">{{ formatPrice(item.couponPrice) }}円</td>
            </template>

            <template #show_details="{ item }">
              <td class="text-center">
                <CButton
                  color="success"
                  shape="square"
                  size="sm"
                  :disabled="item.displayDetails.length === 0"
                  @click="toggleDetails(item.formattedDate)"
                >
                  {{ item.showDetails ? "Hide" : "Show" }}
                </CButton>
              </td>
            </template>

            <template #details="{ item }">
              <CCollapse :show="item.showDetails">
                <CCardBody>
                  <CDataTable :items="item.displayDetails" :fields="detailFields" dark>
                    <template #PLUCode="{ item }">
                      <td class="text-center">
                        {{ item.PLUCode }}
                      </td>
                    </template>

                    <template #title="{ item }">
                      <td class="text-left">
                        {{ item.couponTitle }}
                      </td>
                    </template>

                    <template #purchaseQuantity="{ item }">
                      <td class="text-right">
                        {{ formatPrice(item.purchaseQuantity) }}枚
                      </td>
                    </template>

                    <template #couponPrice="{ item }">
                      <td class="text-right">{{ item.couponPrice }}円</td>
                    </template>
                  </CDataTable>
                </CCardBody>
              </CCollapse>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import firebase from "@firebase/app";
import { CouponStatus, UserRole, ServiceStartDate } from "@/common/const";

const fields = [
  { key: "formattedDate", label: "日付", _style: "width:35%; text-align:center;", },
  { key: "purchaseQuantity", label: "枚数", _style: "width:25%; text-align:center;", },
  { key: "couponPrice", label: "割引金額", _style: "width:25%; text-align:center;", },
  { key: "show_details", label: "内訳", _style: "width:15%;　text-align:center;", sorter: false, },
];

const detailFields = [
  { key: "PLUCode", label: "JANコード", _style: "width:20%; text-align:center;", },
  { key: "title", label: "商品名", _style: "width:40%; text-align:center;" },
  { key: "purchaseQuantity", label: "枚数", _style: "width:20%; text-align:center;", },
  { key: "couponPrice",label: "割引金額", _style: "width:20%; text-align:center;", },
];

export default {
  data() {
    return {
      usageDatas: [],
      usages: [],
      monthlyUsageIds: [],
      details: [],
      fields,
      detailFields,
      user: {},
      closingYearMonths: [],
      dailyUsages: [],
      closable: false,
      userClosing: {
        purchaseQuantity: 0,
        couponPrice: 0,
        note: null,
        usages: [],
        closed: false,
        closed_at: null,
      },
      note: null,
      ocClosed: true,
      toasts: [],
      form: {
          startDate: "",
          endDate: "",
          makerId: "",
          distributionId: "",
          janCode: "",
          storeId: "",
      },
      makerPullDown: [],
      distributionPullDown: [],
      storePullDown: [],
    };
  },
  methods: {
    async init() {
      // 初期化
      this.$store.commit("showLoading")
      this.setYearMonth();
      await this.fetchUser();
      await this.fetchUserClosing();
      await this.isOcClosing();
      this.fetchDaily();
      this.fetchPulldown();
      this.$store.commit("hideLoading")
    },

    setYearMonth() {
      const now = new Date(new Date().toLocaleString({ timeZone: "Asia/Tokyo" }))
      let startDay = new Date(ServiceStartDate.getFullYear(), ServiceStartDate.getMonth(), 1)
      let endDay = new Date(now.getFullYear(), now.getMonth() + 1, 1)
      do {
        this.closingYearMonths.push({
          label: `${startDay.getFullYear()}/${(startDay.getMonth() + 1).toString().padStart(2, "0") }`,
          value: this.toYearMonth(startDay),
        });
        startDay.setMonth(startDay.getMonth() + 1);
      } while (startDay.getTime() !== endDay.getTime());
      this.userClosing.year_month = this.toYearMonth(now);
    },

    async fetchPulldown() {
      // プルダウン用のメーカー一覧取得
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.MAKERS)
        .get()
        .then(
          function(result) {
            let tmp = [];
            result.forEach(function(doc) {
              const parentId = doc.data().parent_id;
              if (parentId == null || parentId == "") {
                tmp.push({ label: doc.data().name, value: doc.data().id });
              }
            });
            this.makerPullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
          }.bind(this)
        );
      // プルダウン用の小売一覧取得
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.DISTRIBUTIONS)
        .get()
        .then(
          function(result) {
            let tmp = [];
            result.forEach(function(doc) {
              const parentId = doc.data().parent_id;
              if (parentId == null || parentId == "") {
                tmp.push({ label: doc.data().name, value: doc.data().id });
              }
            });
            this.distributionPullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
          }.bind(this)
        );
      // プルダウン用の店舗一覧取得
      if (this.isDistributions()) {
        const companyCode = this.user.companyCode
        firebase
          .firestore()
          .collection("stores")
          .where("companyCode", "==", companyCode)
          .get()
          .then(
            function(result) {
              let tmp = [];
              result.forEach(function(doc) {
                const parentId = doc.data().parent_id;
                if (parentId == null || parentId == "") {
                  tmp.push({ label: doc.data().name, value: doc.data().id, storeCode: doc.data().storeCode});
                }
              });
              this.storePullDown = tmp.sort((x, y) => Number(x.storeCode) - Number(y.storeCode));
            }.bind(this)
          );
      }
      if (this.isRegisters()) {
        let companyCodes = [];
        const stores = await firebase.firestore().collection("stores").get();

        let tmp = [];
        const account = this.user;
        stores.forEach(function(doc) {
          const parentId = doc.data().parent_id;
          const myStore = (doc.data().register_id == account.id);
          if (myStore && (parentId == null || parentId == "")) {
            tmp.push({ label: doc.data().name, value: doc.data().id, storeCode: doc.data().storeCode});
             if (companyCodes.indexOf(doc.data().companyCode) < 0) {
              companyCodes.push(doc.data().companyCode)
             }
          }
        });
        this.storePullDown = tmp.sort((x, y) => Number(x.storeCode) - Number(y.storeCode));

        this.distributionPullDown = [];
        let distributionTmp = [];
        for (let companyCode of companyCodes) {
          const userSnapshot = await firebase.firestore().collection("users").where("companyCode", "==", companyCode).get();
          userSnapshot.forEach(function(doc) {
            const parentId = doc.data().parent_id;
            if (parentId == null || parentId == "") {
              distributionTmp.push({ label: doc.data().name, value: doc.data().id });
            }
          })
        }
        this.distributionPullDown = distributionTmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
      }
    },

    async search() {
      const year = parseInt(this.userClosing.year_month.toString().slice(0, 4), 10);
      const month = parseInt(this.userClosing.year_month.toString().slice(4, 6), 10);
      let date = new Date(year, month - 1, 1);

      let startDate = null;
      if (this.form.startDate != '') {
        startDate = new Date(this.form.startDate + 'T00:00:00+09:00')
      }
      let endDate = null;
      if (this.form.endDate != '') {
        endDate = new Date(this.form.endDate + 'T00:00:00+09:00')
      }
      let companyCode = null
      if (this.form.distributionId != null && this.form.distributionId != '') {
        const distributionSnapshot = await firebase.firestore().collection("users").doc(this.form.distributionId).get()
        companyCode = distributionSnapshot.data().companyCode
      }
      let makerId = this.form.makerId
      let storeCode = null
      if (this.form.storeId != null && this.form.storeId != '') {
        const storeSnapshot = await firebase.firestore().collection("stores").doc(this.form.storeId).get()
        storeCode = storeSnapshot.data().storeCode
      }
      let PLUCode = null
      if (this.form.janCode != '') {
        PLUCode = parseInt(this.form.janCode, 10)
      }

      let usageDatas = this.usageDatas.filter(ud => {
        if (PLUCode != null && ud.PLUCode != PLUCode) {
          return false
        }
        if (makerId != null && makerId != '' && ud.maker_id != makerId) {
          return false
        }
        if (companyCode != null && ud.companyCode != companyCode) {
          return false
        }
        if (storeCode != null && ud.storeCode != storeCode) {
          return false
        }
        return true
      });
      let usages = this.groupBy(usageDatas, (usage) => usage.usageDateStr).map(
        ([usageDateStr, items]) => ({
          usageIds: items.map((i) => i.usageId),
          usageDate: this.toDate(usageDateStr),
          formattedDate: this.formatDate(this.toDate(usageDateStr)),
          purchaseQuantity: items.reduce((sum, item) => sum + (item.purchaseQuantity || 0), 0),
          couponPrice: items.reduce((sum, item) => sum + (item.couponPrice || 0), 0),
          details: this.groupBy(items, (item) => item.PLUCode)
            .map(([PLUCode, usages]) => ({
              PLUCode: PLUCode,
              companyCode: usages[0].companyCode,
              storeCode: usages[0].storeCode,
              couponTitle: usages[0].couponTitle,
              purchaseQuantity: usages.reduce((sum, usage) => sum + (usage.purchaseQuantity || 0), 0),
              couponPrice: usages.reduce((sum, usage) => sum + (usage.couponPrice || 0), 0),
            }))
            .sort((d1, d2) => d1.PLUCode - d2.PLUCode),
          showDetails: false,
        })
      );

      this.dailyUsages = []
      while (date.getMonth() + 1 === month) {
        const usageDate = new Date(date);
        if (startDate != null && usageDate.getTime() < startDate.getTime()) {
          date.setDate(date.getDate() + 1);
          continue;
        }
        if (endDate != null && usageDate.getTime() > endDate.getTime()) {
          date.setDate(date.getDate() + 1);
          continue;
        }
        let usage = usages.find(
          (us) => us.usageDate.getTime() === usageDate.getTime()
        );

        if (usage != null) {
          usage.showDetails = false
          usage.displayDetails = []
          let purchaseQuantity = 0
          let couponPrice = 0
          for (var detail of usage.details) {
            purchaseQuantity += detail.purchaseQuantity
            couponPrice += detail.couponPrice
            usage.displayDetails.push(detail)
          }
          usage.purchaseQuantity = purchaseQuantity
          usage.couponPrice = couponPrice
          this.dailyUsages.push(usage);
        } else {
          this.dailyUsages.push({
            usageDate: usageDate,
            formattedDate: this.formatDate(usageDate),
            purchaseQuantity: 0,
            couponPrice: 0,
            details: [],
            displayDetails: [],
            showDetails: false,
          });
        }
        date.setDate(date.getDate() + 1);
      }
    },
    async download() {
      await this.search()
      let csv = '\ufeff' + '日付,JANコード,商品名,枚数,割引金額\n'
      for (let dailyUsage of this.dailyUsages) {
        csv = csv
          + dailyUsage.formattedDate.replace(/ /g, "") + ","
          + ","
          + ","
          + dailyUsage.purchaseQuantity + ","
          + dailyUsage.couponPrice + "\n"
        for (let detail of dailyUsage.displayDetails) {
          csv = csv
            + ","
            + detail.PLUCode + ","
            + detail.couponTitle + ","
            + detail.purchaseQuantity + ","
            + detail.couponPrice + "\n"
        }
      }
      let blob = new Blob([csv], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'UserClosing.csv'
      link.click()
    },
    onUpdateMaker(value) {
      this.form.makerId = value;
    },
    async onUpdateDistribution(value) {
      this.form.distributionId = value;
      // プルダウン用の店舗一覧取得
      if (!this.isDistributions() && !this.isRegisters()) {
        this.form.storeId = null
        const userSnapshot = await firebase.firestore().collection("users").doc(value).get()
        const companyCode = userSnapshot.data().companyCode
        firebase
          .firestore()
          .collection("stores")
          .where("companyCode", "==", companyCode)
          .get()
          .then(
            function(result) {
              let tmp = [];
              result.forEach(function(doc) {
                const parentId = doc.data().parent_id;
                if (parentId == null || parentId == "") {
                  tmp.push({ label: doc.data().name, value: doc.data().id, storeCode: doc.data().storeCode});
                }
              });
              this.storePullDown = tmp.sort((x, y) => Number(x.storeCode) - Number(y.storeCode));
            }.bind(this)
          );
      }
    },
    onUpdateStore(value) {
      this.form.storeId = value;
    },
    isDistributions() {
      return this.user.role == UserRole.DISTRIBUTIONS
    },
    isMakers() {
      return this.user.role == UserRole.MAKERS
    },
    isWholeSales() {
      return this.user.role == UserRole.WHOLESALE
    },
    isRegisters() {
      return this.user.role == UserRole.REGISTER
    },

    async fetchUser() {
      const firebaseUser = firebase.auth().currentUser;
      // 未ログイン時はなにもしない
      if (firebaseUser == null) {
        return;
      }
      let uid
      uid = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(uid).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        uid = parent_id
      }
      const userSnapShot = await firebase.firestore()
        .collection("users")
        .doc(uid)
        .get();
      this.user = userSnapShot.data();

      this.usageDatas = [];
      if (this.user.role === UserRole.DISTRIBUTIONS) {
        // 小売
        const formattedCompanyCode = ("000000" + this.user.companyCode).slice(-6)
        const usages = await firebase.firestore()
          .collection("usages")
          .where("companyCode", "==", formattedCompanyCode)
          .get();
        this.usageDatas = usages.docs;
      } else if (this.user.role === UserRole.MAKERS) {
        // メーカー
        const usages = await firebase.firestore()
          .collection("usages")
          .where("maker_id", "==", this.user.id)
          .where("distributionFlag", "==", false)
          .get();
        this.usageDatas = usages.docs;
      } else if (this.user.role === UserRole.WHOLESALE) {
        // 卸
        const usages = await firebase.firestore()
          .collection("usages")
          .where("wholesale_id", "==", this.user.id)
          .where("distributionFlag", "==", false)
          .get();
        this.usageDatas = usages.docs;
      } else if (this.user.role === UserRole.REGISTER) {
        // レジ
        const usages = await firebase.firestore()
          .collection("usages")
          .where("register_id", "==", this.user.id)
          .where("distributionFlag", "==", false)
          .get();
        this.usageDatas = usages.docs;
      }

      this.usageDatas = this.usageDatas.map((doc) => {
        const usage = doc.data();
        return {
          usageId: doc.id,
          usageDateStr: usage.usageTime.toString().slice(0, 8),
          usageTime: usage.usageTime,
          purchaseQuantity: usage.purchaseQuantity,
          couponPrice: usage.couponPrice,
          PLUCode: usage.PLUCode,
          companyCode: parseInt(usage.companyCode, 10),
          storeCode: parseInt(usage.storeCode, 10),
          couponTitle: usage.title ? usage.title : "（削除済み）",
        };
      });
      this.usages = this.groupBy(this.usageDatas, (usage) => usage.usageDateStr).map(
        ([usageDateStr, items]) => ({
          usageIds: items.map((i) => i.usageId),
          usageDate: this.toDate(usageDateStr),
          formattedDate: this.formatDate(this.toDate(usageDateStr)),
          purchaseQuantity: items.reduce((sum, item) => sum + (item.purchaseQuantity || 0), 0),
          couponPrice: items.reduce((sum, item) => sum + (item.couponPrice || 0), 0),
          details: this.groupBy(items, (item) => item.PLUCode)
            .map(([PLUCode, usages]) => ({
              PLUCode: PLUCode,
              companyCode: usages[0].companyCode,
              storeCode: usages[0].storeCode,
              couponTitle: usages[0].couponTitle,
              purchaseQuantity: usages.reduce((sum, usage) => sum + (usage.purchaseQuantity || 0), 0),
              couponPrice: usages.reduce((sum, usage) => sum + (usage.couponPrice || 0), 0),
            }))
            .sort((d1, d2) => d1.PLUCode - d2.PLUCode),
          showDetails: false,
        })
      );
    },

    async fetchUserClosing() {
      let uid
      uid = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(uid).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        uid = parent_id
      }
      const userRef = firebase.firestore()
        .collection("users")
        .doc(uid);
      const userClosingSnapShot = await firebase.firestore()
        .collection("userClosings")
        .where("user", "==", userRef)
        .where("year_month", "==", this.userClosing.year_month)
        .get();
      if (userClosingSnapShot.docs.length > 0) {
        userClosingSnapShot.forEach((doc) => {
          this.userClosing = doc.data();
        });
      } else {
        const userRef = firebase.firestore()
          .collection("users")
          .doc(this.user.id)
        this.userClosing.user = userRef
        this.userClosing.note = null
        this.userClosing.closed = false
        this.userClosing.closed_at = null
      }
    },
    async isOcClosing() {
      const ocClosingSnapShot = await firebase.firestore()
        .collection("ocClosings")
        .where("year_month", "==", this.userClosing.year_month)
        .get();
      this.ocClosed = ocClosingSnapShot.docs.length > 0;
    },

    fetchDaily() {
      const now = new Date(
        new Date().toLocaleString({ timeZone: "Asia/Tokyo" })
      );
      const nowDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      const year = parseInt(this.userClosing.year_month.toString().slice(0, 4), 10);
      const month = parseInt(this.userClosing.year_month.toString().slice(4, 6), 10);
      let date = new Date(year, month - 1, 1);

      this.monthlyUsageIds = [];
      this.dailyUsages = [];
      if (!this.userClosing.closed) {
        this.userClosing.purchaseQuantity = 0;
        this.userClosing.couponPrice = 0;
      }
      while (date.getMonth() + 1 === month) {
        const usageDate = new Date(date);
        if (usageDate.getTime() === nowDate.getTime()) {
          this.closable = false;
          break;
        }
        let usage = this.usages.find(
          (us) => us.usageDate.getTime() === usageDate.getTime()
        );

        if (usage != null) {
          this.monthlyUsageIds = this.monthlyUsageIds.concat(usage.usageIds);
          usage.displayDetails = usage.details
          this.dailyUsages.push(usage);
          if (!this.userClosing.closed) {
            this.userClosing.purchaseQuantity += usage.purchaseQuantity || 0;
            this.userClosing.couponPrice += usage.couponPrice || 0;
          }
          this.closable = true;
        } else {
          this.dailyUsages.push({
            usageDate: usageDate,
            formattedDate: this.formatDate(usageDate),
            purchaseQuantity: 0,
            couponPrice: 0,
            details: [],
            displayDetails: [],
            showDetails: false,
          });
        }
        date.setDate(date.getDate() + 1);
      }
    },

    toggleDetails(formattedDate) {
      this.dailyUsages.forEach((u) => {
        if (u.formattedDate == formattedDate) {
          u.showDetails = !u.showDetails;
        }
      });
    },
    async changeYearMonth(value) {
      this.userClosing.year_month = value;
      this.$store.commit("showLoading")
      await this.fetchUserClosing();
      await this.isOcClosing();
      this.fetchDaily();
      this.$store.commit("hideLoading")
    },

    async validClose() {
      if (!this.isCloseUser()) return false;

      const userClosingSnapShot = await firebase.firestore()
        .collection("userClosings")
        .where("user", "==", this.userClosing.user)
        .where("year_month", "==", this.userClosing.year_month)
        .get();

      if (userClosingSnapShot.docs.some((closing) => closing.data().closed)) {
        this.toasts.push({ level: "danger", message: "すでに確定済みです。" });
        return false;
      }

      this.isOcClosing();
      if (this.ocClosed) {
        this.toasts.push({level: "danger", message: "すでにオープンクーポン社が確定済みです。",});
        return false;
      }

      return true
    },
    async close() {
      if (!await this.validClose()) return false

      const batch = firebase.firestore().batch();

      let usageRefs = [];
      this.monthlyUsageIds.forEach((id) => {
        const usageRef = firebase.firestore().collection("usages").doc(id);
        usageRefs.push(usageRef);
      });

      this.userClosing.usages = usageRefs;
      this.userClosing.closed = true;
      this.userClosing.closed_at = new Date(new Date().toLocaleString({ timeZone: "Asia/Tokyo" }));

      const userClosingSnapShot = await firebase.firestore()
        .collection("userClosings")
        .where("user", "==", this.userClosing.user)
        .where("year_month", "==", this.userClosing.year_month)
        .get();
      if (userClosingSnapShot.docs.length > 0) {
        userClosingSnapShot.forEach((doc) => {
          batch.set(doc.ref, this.userClosing);
        });
      } else {
        const userClosingRef = await firebase.firestore()
          .collection("userClosings")
          .doc();
        batch.set(userClosingRef, this.userClosing);
      }
      await batch.commit();
      this.toasts.push({ level: "success", message: "確定しました。" });
    },

    async validRelease() {
      if (!this.isCloseUser()) return false;

      const userClosingSnapShot = await firebase.firestore()
        .collection("userClosings")
        .where("user", "==", this.userClosing.user)
        .where("year_month", "==", this.userClosing.year_month)
        .get();

      if (!userClosingSnapShot.docs.every((closing) => closing.data().closed)) {
        this.toast.push({ level: "danger", message: "確定されていません。" });
        return false
      }

      this.isOcClosing();
      if (this.ocClosed) {
        this.toasts.push({ level: "danger", message: "すでにオープンクーポン社が確定済みです。" });
        return false
      }

      return true
    },
    async release() {
      if (!await this.validRelease()) return false;

      const batch = firebase.firestore().batch();

      this.userClosing.usages = null;
      this.userClosing.closed = false;
      this.userClosing.closed_at = null;

      const userClosingSnapShot = await firebase.firestore()
        .collection("userClosings")
        .where("user", "==", this.userClosing.user)
        .where("year_month", "==", this.userClosing.year_month)
        .get();

      userClosingSnapShot.forEach((doc) => {
        batch.set(doc.ref, this.userClosing);
      });
      await batch.commit();
      this.toasts.push({ level: "success", message: "確定解除しました。" });
    },
    async registerNote() {
      if (!this.isCloseUser()) return false;

      const userClosingSnapShot = await firebase.firestore()
        .collection("userClosings")
        .where("user", "==", this.userClosing.user)
        .where("year_month", "==", this.userClosing.year_month)
        .get();
      if (userClosingSnapShot.docs.length > 0) {
        userClosingSnapShot.forEach((doc) => {
          doc.ref.set(this.userClosing);
        });
      } else {
        const userClosingDoc = await firebase.firestore()
          .collection("userClosings")
          .doc();
        userClosingDoc.set(this.userClosing);
      }
      this.toasts.push({
        level: "success",
        message: "申し送り事項を登録しました。",
      });
    },

    groupBy(array, getKey) {
      return Array.from(
        array.reduce((map, cur, idx, src) => {
          const key = getKey(cur, idx, src);
          const list = map.get(key);
          if (list) list.push(cur);
          else map.set(key, [cur]);
          return map;
        }, new Map())
      );
    },
    toDate(dateStr) {
      if (dateStr.length !== 8) return null;
      return new Date(
        dateStr.slice(0, 4),
        dateStr.slice(4, 6) - 1,
        dateStr.slice(6, 8)
      );
    },
    toYearMonth(date) {
      if (date == null) return null;
      return parseInt(date.getFullYear().toString() + ("00" + (date.getMonth() + 1)).slice(-2));
    },
    formatDate(date) {
      if (date == null) return null;
      return `
      ${date.getFullYear()}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}
      (${["日", "月", "火", "水", "木", "金", "土"][date.getDay()]})
      `.replace(/\n|\r/g, "");
    },
    formatPrice(price) {
      return String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },

    isCloseUser() {
      return this.user.role == UserRole.DISTRIBUTIONS
        || this.user.role == UserRole.MAKERS
        || this.user.role == UserRole.WHOLESALE
    }
  },
  created() {
    this.init();
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
};
</script>
